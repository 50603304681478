import React, { useEffect, useState } from 'react'

import '../../../styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'



const fiscalYears = [
    "2023",
    "2021",
    "2022",
    "2024",
    "2025"
];

export default function ScanLabNumbersDisplay(props) {
    const {
        samplePackageTracking,
        labNumber,
        sampleType,
        lab,
        fiscalYear,
        handleUpdateScanLabNumbers,
        handleCancelScanLabNumbers,
        handleSubmitScanLabNumbers,
        sampleTypeOs,
        labOs
    } = props;

    const [labs, setlabOptions] = useState([{
        "Lab_ID": "",
        "Lab_VC": ""
    }])
    const [sampleTypes, setSampleTypes] = useState([{
        "SampleType_ID": "",
        "SampleType_VC": ""
    }])


    useEffect(() => {
        if (sampleTypeOs.length && labOs.length) {
            setlabOptions(labOs)
            setSampleTypes(sampleTypeOs)
        }
    }, [sampleTypeOs, labOs])

    return (
        <>
            <div className="container-fluid px-2" style={{ "marginTop": "5px" }}>
                <div className="card mx-auto">
                    <div className="card-body">
                        <div className="service-heading mb-2" style={{"marginTop":"-20px"}}>
                            Scan Lab Numbers
                        </div>
                        <hr style={{"marginTop":"-20px"}}/>
                        <div>
                            <div className="row">
                                <div className="col" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                                    <label htmlFor="prefParcelTrackingID" className="form-label service-form-label">
                                        ServiTech Sample Package Tracking ID:&nbsp;&nbsp;
                                    </label>
                                    <input type="text" className="form-control service-form-control" id="prefParcelTrackingID" value={samplePackageTracking} onChange={(e) => handleUpdateScanLabNumbers(e)} />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-sm-2" >
                                    <label htmlFor="prefLabNumber" className="form-label service-form-label">
                                        Lab Number:&nbsp;&nbsp;
                                    </label>
                                </div>
                                <div className="col-sm-4" >
                                    <input type="text" className="form-control service-form-control" id="prefLabNumber" value={labNumber} onChange={(e) => handleUpdateScanLabNumbers(e)} />
                                </div>
                                <div className="col-sm-2" >
                                    <label htmlFor="prefSampleType" className="form-label service-form-label">
                                        Sample Type:&nbsp;&nbsp;
                                    </label>
                                </div>
                                <div className="col-sm-4" >
                                    <select className="form-select service-form-control" id="prefSampleType" value={sampleType.SampleType_VC} onChange={(e) => handleUpdateScanLabNumbers(e)}>
                                        <option value="" disabled>--Select Sample Type--</option>
                                        {sampleTypes.map((state, index) =>
                                            <option key={index} value={state.SampleType_VC}>{state.SampleType_VC}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-sm-2">
                                    <label htmlFor="prefLab" className="form-label service-form-label">
                                        Lab:&nbsp;&nbsp;
                                    </label>
                                </div>
                                <div className="col-sm-4">
                                    <select className="form-select service-form-control" id="prefLab" value={lab.Lab_VC} onChange={(e) => handleUpdateScanLabNumbers(e)}>
                                        {labs.map((state, index) =>
                                            <option key={index} value={state}>{state.Lab_VC}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <label htmlFor="prefFiscalYear" className="form-label service-form-label">
                                        Fiscal Year:&nbsp;&nbsp;
                                    </label>
                                </div>
                                <div className="col-sm-4">
                                    <select className="form-select service-form-control" id="prefFiscalYear" value={fiscalYear} onChange={(e) => handleUpdateScanLabNumbers(e)}>
                                        {fiscalYears.map((state, index) =>
                                            <option key={index} value={state}>{state}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-sm-6">
                                    <button type="button" className="button-max-primary" onClick={(e) => handleSubmitScanLabNumbers(e)}>OK</button>
                                </div>
                                <div className="col-sm-6">
                                    <button type="button" className="button-max-secondary" onClick={(e) => handleCancelScanLabNumbers(e)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


