import React, { useState, useEffect } from 'react';

import ScanLabNumbersDisplay from './ScanLabNumbersDisplay';
import { MessageModal } from '../MessageModal';

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../styles.css'

const sptGQLURI = 'https://gql-sample-package-tracking-bjudfyf5za-uc.a.run.app/graphql';


const ScanLabNumbers = (props) => {
    const { globalVs, labs, sampleTypes } = props;

    const [samplePackageTracking, setSamplePackageTracking] = useState("");
    const [labNumber, setLabNumber] = useState("");
    const [sampleType, setSampleType] = useState({
        "SampleType_ID": "",
        "SampleType_VC": ""
    });
    const [lab, setLab] = useState({
        "Lab_ID": "",
        "Lab_VC": ""
    });
    const [fiscalYear, setFiscalYear] = useState("2025");

    const[showMessageModal, setShowMessageModal] = useState(false)
    const [displayMessage, setDisplayMessage] = useState("");

    const handleUpdateScanLabNumbers = (e) => {
        e.preventDefault();
        const value = e.target.value;
        switch (e.target.id) {
            case "prefParcelTrackingID":
                setSamplePackageTracking(value)
                break;
            case "prefLabNumber":
                setLabNumber(value)
                break;
            case "prefSampleType":
                const st = sampleTypes.find(x => x.SampleType_VC === value)
                setSampleType(st)
                break;
            case "prefLab":
                setLab(value)
                break;
            case "prefFiscalYear":
                setFiscalYear(value)
                break;
            default:
                break;
        }
    }

    const handleCancelScanLabNumbers = (e) => {
        e.preventDefault();
        setSamplePackageTracking("")
        setLabNumber("")
        // setFiscalYear("2024")
    }

    const handleSubmitScanLabNumbers = async (e) => {
        const addScannedLabNumbersQuery = `
            {
                AddScannedLabNumbers(
                    fiscalYearId:${JSON.stringify(fiscalYear)},
                    labNumberIn:${JSON.stringify(labNumber)},
                    sampleTypeId:${JSON.stringify(sampleType.SampleType_ID)},
                    labId:${JSON.stringify(lab.Lab_ID)},
                    g_AdminUser_ID:${JSON.stringify(globalVs.AdminUser_ID)},
                    samplePackageTrackingId:${JSON.stringify(samplePackageTracking)}
                )
            }
        `;
        console.log(addScannedLabNumbersQuery);
        const addScannedLabNumbersFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: addScannedLabNumbersQuery }), headers: { 'Content-Type': 'application/json' } });
        const addScannedLabNumbersJSON = await addScannedLabNumbersFetch.json();
        if (addScannedLabNumbersJSON && addScannedLabNumbersJSON.data && addScannedLabNumbersJSON.data.AddScannedLabNumbers && addScannedLabNumbersJSON.data.AddScannedLabNumbers === "Added Scanned Lab Numbers") {
            setDisplayMessage('Scan Lab Numbers Was Successful')
            setShowMessageModal(true)
            setTimeout(() => {
                setShowMessageModal(false)
                setDisplayMessage("")
                handleCancelScanLabNumbers(e)
            }, 3000)
        } else {
            setDisplayMessage('Could Not Scan Lab Numbers. Please Contact IT')
            setShowMessageModal(true)
            setTimeout(() => {
                setShowMessageModal(false)
                setDisplayMessage("")
                handleCancelScanLabNumbers(e)
            }, 3000)
        }
    }

    useEffect(() => {
        let labOs = labs;
        const userDefaultLabId = globalVs.DefaultLab_ID;
        const defaultLabOption = labOs.find(dlab => dlab.Lab_ID === userDefaultLabId);
        setLab(defaultLabOption)
    }, [labs, globalVs])

    return (
        <>
            <ScanLabNumbersDisplay
                samplePackageTracking={samplePackageTracking}
                labNumber={labNumber}
                sampleType={sampleType}
                lab={lab}
                fiscalYear={fiscalYear}
                handleUpdateScanLabNumbers={handleUpdateScanLabNumbers}
                handleCancelScanLabNumbers={handleCancelScanLabNumbers}
                handleSubmitScanLabNumbers={handleSubmitScanLabNumbers}
                labOs={labs}
                sampleTypeOs={sampleTypes}
            />
            <MessageModal
                isOpen={showMessageModal}
                setShowEmailModal={setShowMessageModal}
                displayMessage={displayMessage}
            />
        </>
    )
}
export default ScanLabNumbers;